export const UPDATE_FIELD = 'UPDATE_FIELD'

export const GET_SUCCESS_VENUE_SLUGS = 'GET_SUCCESS_VENUE_SLUGS'
export const GET_FAIL_VENUE_SLUGS = 'GET_FAIL_VENUE_SLUGS'
export const NEW_VENUE_SLUG = 'NEW_VENUE_SLUG'
export const FAILED_VENUE_SLUG_SAVE = 'FAILED_VENUE_SLUG_SAVE'
export const NEW_VENUE_SLUG_NEVERMIND = 'NEW_VENUE_SLUG_NEVERMIND'
export const INITIATE_UPDATE_IN_PROGRESS = 'INITIATE_UPDATE_IN_PROGRESS'
export const SHOW_EXPERIENCE_PAGE_LINKS = 'SHOW_EXPERIENCE_PAGE_LINKS'
export const HIDE_EXPERIENCE_PAGE_LINKS = 'HIDE_EXPERIENCE_PAGE_LINKS'
