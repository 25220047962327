import { Provider } from 'react-redux'
import { useForm } from '@sevenrooms/core/form'
import { Router } from '@sevenrooms/core/navigation'
import { fileFormSchema } from '@sevenrooms/core/ui-kit/form'
import { Root } from '@sevenrooms/core/ui-kit/layout'
import { store } from './store'
import { Widget } from './Widget'

interface AppProps {
  onClose: () => void
  venueId: string
  mediaUrl: string
}

export function App({ onClose, venueId, mediaUrl }: AppProps) {
  const { field } = useForm(fileFormSchema, { mode: 'onChange' })

  return (
    <Provider store={store}>
      <Router>
        <Root theme="vx">
          <Widget onClose={onClose} field={field.prop('file')} venueId={venueId} mediaUrl={mediaUrl} />
        </Root>
      </Router>
    </Provider>
  )
}
