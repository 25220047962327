/* eslint-disable camelcase */
import _ from 'lodash'
import { srDelete, srGet, srPost } from '@sevenrooms/core/api'
import { copyToClipboard } from '@sevenrooms/core/ui-kit/utils'
import {
  NEW_VENUE_SLUG,
  NEW_VENUE_SLUG_NEVERMIND,
  FAILED_VENUE_SLUG_SAVE,
  GET_SUCCESS_VENUE_SLUGS,
  UPDATE_FIELD,
  INITIATE_UPDATE_IN_PROGRESS,
  SHOW_EXPERIENCE_PAGE_LINKS,
  HIDE_EXPERIENCE_PAGE_LINKS,
} from './ActionTypes'

export const getSuccessVenueSlugs = venueSlugsResponse => ({ type: GET_SUCCESS_VENUE_SLUGS, venueSlugsResponse })

export const fetchVenueSlugs = (venueId, errHandler) =>
  srGet(`/api-yoa/venue_slugs`, { venue: venueId }).then(response => {
    if (response.error && errHandler) {
      return { error: response.error }
    }
    return response.data
  })

export const saveNewVenueSlug = (venueId, name, user_id, link_id, errHandler) =>
  srPost(`/api-yoa/venue_slugs`, {
    venue: venueId,
    name,
    user_id,
    link_id,
  }).then(response => {
    if (response.error && errHandler) {
      return { error: response.error }
    }
    return response.data
  })

export const deleteVenueSlug = (venueId, slugId) => srDelete(`/api-yoa/venue_slugs`, { venue: venueId, slug_id: slugId })

export const getFailVenueSlug = e => {
  window.Interface._alert(e || 'Oops... encountered error. Please try again.')
  return { type: UPDATE_FIELD }
}

export const updateField = e => {
  const field = e.target.name
  const { value } = e.target
  return { type: UPDATE_FIELD, field, value }
}

export const tryAddingVenueSlug = () => ({ type: NEW_VENUE_SLUG })

export const failedSaveVenueSlug = () => ({ type: FAILED_VENUE_SLUG_SAVE })

export const newVenueSlugNevermind = () => ({ type: NEW_VENUE_SLUG_NEVERMIND })
export const showExperiencePageLinks = () => ({ type: SHOW_EXPERIENCE_PAGE_LINKS })
export const hideExperiencePageLinks = () => ({ type: HIDE_EXPERIENCE_PAGE_LINKS })

export const tryDeletingSlug = (venueId, slugId) => dispatch => {
  dispatch(initiateUpdateFlag())
  const errHandler = () => dispatch(getFailVenueSlug())
  deleteVenueSlug(venueId, slugId).then(() => dispatch(tryGetVenueSlugs(venueId)), errHandler)
}

export const tryCopyHandler = (linkType, slugId) => (dispatch, getState) => {
  const state = getState()
  const linkOptions = _.union(state.venueSlugs.venue_promoter_slugs, state.venueSlugs.venue_user_slugs, state.venueSlugs.common_slugs)
  const selectedLink = _.find(linkOptions, { id: slugId })
  const venueId = document.getElementById('_venue_id').value
  const selectedLinkValue = selectedLink ? selectedLink.slug_value : null

  copyToClipboard(`${window.location.origin}/${linkType}/${venueId}/${selectedLinkValue}`)

  window.Interface.alertSuccess('Link copied!')
  return { type: UPDATE_FIELD }
}

export const initiateUpdateFlag = () => ({ type: INITIATE_UPDATE_IN_PROGRESS })

export const onNewTrackSave = () => (dispatch, getState) => {
  const state = getState()
  const transientLink = document.getElementById('id_linkId')
  const transientLinkValue = transientLink ? transientLink.value : null
  const linkId = transientLinkValue || state.appState.linkId
  const errHandler = () => dispatch(getFailVenueSlug())
  if (_.isEmpty(state.appState.userId)) {
    return dispatch(getFailVenueSlug('Please select valid user'))
  }
  if (_.isEmpty(linkId) || !/^[A-Za-z0-9]+(?:-[A-Za-z0-9_]+)*$/.test(linkId)) {
    return dispatch(getFailVenueSlug('Error: Invalid link id entered'))
  }

  dispatch(initiateUpdateFlag())
  return saveNewVenueSlug(state.venueSlugs.venue_id, state.appState.name, state.appState.userId, linkId, errHandler).then(
    venueSlugResponse => {
      if ('error' in venueSlugResponse) {
        return dispatch(failedSaveVenueSlug())
      }
      return dispatch(tryGetVenueSlugs(state.venueSlugs.venue_id))
    }
  )
}

export const tryGetVenueSlugs = venueId => dispatch => {
  const errHandler = () => dispatch(getFailVenueSlug())
  fetchVenueSlugs(venueId, errHandler).then(venueSlugResponse => dispatch(getSuccessVenueSlugs(venueSlugResponse)))
}
