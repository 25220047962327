/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-noninteractive-element-to-interactive-role */
import _ from 'lodash'
import React from 'react'
import ReactTooltip from 'react-tooltip'
import * as styles from '../assets/styles'
import Button from '../../../../common/Button'

const getReservationLink = (origin, venueId, slug_value, new_reservation_widget_enabled, force_redirects_on_legacy_widget) =>
  new_reservation_widget_enabled && force_redirects_on_legacy_widget
    ? `${origin}/explore/${venueId}/reservations/create/search?tracking=${slug_value}`
    : `${origin}/reservations/${venueId}/${slug_value}`

const getEventLink = (origin, venueId, slug_value) => `${origin}/events/${venueId}/${slug_value}`

const getExperienceDirectoryLink = (origin, venueId, slug_value) => `${origin}/experiences/${venueId}?tracking=${slug_value}`

const getExperienceLink = (origin, venueId, slug_value, landing_url_key) =>
  `${origin}/experiences/${venueId}/${landing_url_key}?tracking=${slug_value}`

export const TrackingLinksWidget = ({
  MEDIA_URL,
  currentVenueUserSlug,
  venueUserSlugs,
  venuePromoterSlugs,
  commonSlugs,
  onDeleteHandler,
  venueId,
  event_widget_enabled,
  dining_widget_enabled,
  new_reservation_widget_enabled,
  force_redirects_on_legacy_widget,
  copyToClipboard,
  experiences,
  isShowExperiencePageLinks,
  hideExperiencePageLinks,
  showExperiencePageLinks,
}) => {
  const trackingUserLinkRow = (slug, key) => {
    const reservationLink = getReservationLink(
      window.location.origin,
      venueId,
      slug.slug_value,
      new_reservation_widget_enabled,
      force_redirects_on_legacy_widget
    )
    const eventLink = getEventLink(window.location.origin, venueId, slug.slug_value)
    const experienceDirectoryLink = getExperienceDirectoryLink(window.location.origin, venueId, slug.slug_value)

    return (
      <div key={key} style={styles.tableStyle}>
        <ReactTooltip id={`tip-${slug.id}`} effect="solid" />
        <div style={styles.nonLinkColumn}>{slug.name}</div>
        <div style={styles.nonLinkColumn}>{slug.slug_value}</div>
        <div style={styles.linkColumn}>
          <table style={styles.linkTable}>
            <tbody>
              {event_widget_enabled ? (
                <tr style={styles.linkTableTR}>
                  <td style={styles.linkTableTDLink}>
                    <p>{eventLink}</p>
                  </td>
                  <td style={styles.linkTableTD}>
                    <p
                      data-for={`tip-${slug.id}`}
                      data-tip="Copy events tracking link"
                      style={styles.actionLink(MEDIA_URL)}
                      onClick={() => copyToClipboard(eventLink)}
                    />
                  </td>
                </tr>
              ) : null}
              {dining_widget_enabled ? (
                <tr style={styles.linkTableTR}>
                  <td style={styles.linkTableTDLink}>
                    <p>{reservationLink}</p>
                  </td>
                  <td style={styles.linkTableTD}>
                    <p
                      data-test={`copy-link-${slug.slug_value}`}
                      data-for={`tip-${slug.id}`}
                      data-tip="Copy dining tracking link"
                      style={styles.actionLink(MEDIA_URL)}
                      onClick={() => copyToClipboard(reservationLink)}
                    />
                  </td>
                </tr>
              ) : null}
              {isShowExperiencePageLinks ? (
                <>
                  <tr style={styles.linkTableTR}>
                    <td style={styles.linkTableTDLink}>
                      <p>{experienceDirectoryLink}</p>
                    </td>
                    <td style={styles.linkTableTD}>
                      <p
                        data-test={`copy-link-experiences-${slug.slug_value}`}
                        data-for={`tip-experiences-${slug.id}`}
                        data-tip="Copy dining tracking link"
                        style={styles.actionLink(MEDIA_URL)}
                        onClick={() => copyToClipboard(experienceDirectoryLink)}
                      />
                    </td>
                  </tr>
                  {experiences.map(experience => {
                    const experienceLink = getExperienceLink(window.location.origin, venueId, slug.slug_value, experience.landing_url_key)
                    return (
                      <tr style={styles.linkTableTR} key={experience.id}>
                        <td style={styles.linkTableTDLink}>
                          <p>{experienceLink}</p>
                        </td>
                        <td style={styles.linkTableTD}>
                          <p
                            data-test={`copy-link-${experience.id}`}
                            data-for={`tip-${experience.id}`}
                            data-tip="Copy dining tracking link"
                            style={styles.actionLink(MEDIA_URL)}
                            onClick={() => copyToClipboard(experienceLink)}
                          />
                        </td>
                      </tr>
                    )
                  })}
                </>
              ) : null}
            </tbody>
          </table>
        </div>
        {!slug.is_static ? (
          <p
            data-for={`tip-${slug.id}`}
            data-tip="Delete tracking link"
            style={styles.actionDelete(MEDIA_URL)}
            onClick={onDeleteHandler.bind(null, venueId, slug.id)}
          />
        ) : null}
      </div>
    )
  }

  const trackingPromoterLinkRow = (slug, key) => {
    const reservationLink = getReservationLink(
      window.location.origin,
      venueId,
      slug.slug_value,
      new_reservation_widget_enabled,
      force_redirects_on_legacy_widget
    )
    const eventLink = getEventLink(window.location.origin, venueId, slug.slug_value)
    const experienceDirectoryLink = getExperienceDirectoryLink(window.location.origin, venueId, slug.slug_value)

    return (
      <div key={key} style={styles.tableStyle}>
        <ReactTooltip id={`tip-${slug.id}`} effect="solid" />
        <div style={styles.nonLinkColumn}>{slug.name}</div>
        <div style={styles.nonLinkColumn}>{slug.slug_value}</div>
        <div style={styles.linkColumn}>
          <table>
            <tbody>
              {event_widget_enabled ? (
                <tr style={styles.linkTableTR}>
                  <td style={styles.linkTableTDLink}>
                    <p>{eventLink}</p>
                  </td>
                  <td style={styles.linkTableTD}>
                    <p
                      data-for={`tip-${slug.id}`}
                      data-tip="Copy events tracking link"
                      style={styles.actionLink(MEDIA_URL)}
                      onClick={() => copyToClipboard(eventLink)}
                    />
                  </td>
                </tr>
              ) : null}
              {dining_widget_enabled ? (
                <tr style={styles.linkTableTR}>
                  <td style={styles.linkTableTDLink}>
                    <p>{reservationLink}</p>
                  </td>
                  <td style={styles.linkTableTD}>
                    <p
                      data-test={`copy-link-${slug.slug_value}`}
                      data-for={`tip-${slug.id}`}
                      data-tip="Copy dining tracking link"
                      style={styles.actionLink(MEDIA_URL)}
                      onClick={() => copyToClipboard(reservationLink)}
                    />
                  </td>
                </tr>
              ) : null}
              {isShowExperiencePageLinks ? (
                <tr style={styles.linkTableTR}>
                  <td style={styles.linkTableTDLink}>
                    <p>{experienceDirectoryLink}</p>
                  </td>
                  <td style={styles.linkTableTD}>
                    <p
                      data-test={`copy-link-experiences-promoter-${slug.slug_value}`}
                      data-for={`tip-experiences-promoter-${slug.id}`}
                      data-tip="Copy dining tracking link"
                      style={styles.actionLink(MEDIA_URL)}
                      onClick={() => copyToClipboard(experienceDirectoryLink)}
                    />
                  </td>
                </tr>
              ) : null}
              {isShowExperiencePageLinks
                ? experiences.map(experience => {
                    const experienceLink = getExperienceLink(window.location.origin, venueId, slug.slug_value, experience.landing_url_key)
                    return (
                      <tr style={styles.linkTableTR} key={experience.id}>
                        <td style={styles.linkTableTDLink}>
                          <p>{experienceLink}</p>
                        </td>
                        <td style={styles.linkTableTD}>
                          <p
                            data-test={`copy-link-${experience.id}`}
                            data-for={`tip-${experience.id}`}
                            data-tip="Copy dining tracking link"
                            style={styles.actionLink(MEDIA_URL)}
                            onClick={() => copyToClipboard(experienceLink)}
                          />
                        </td>
                      </tr>
                    )
                  })
                : null}
            </tbody>
          </table>
        </div>
        <p
          data-for={`tip-${slug.id}`}
          data-tip="Delete tracking link"
          style={styles.actionDelete(MEDIA_URL)}
          onClick={onDeleteHandler.bind(null, venueId, slug.id)}
        />
      </div>
    )
  }
  const toggleExperiencesPageTrackingLinkButton = () => (
    <div data-test="toggle-experience-tracking-links" id="toggle-experience-tracking-links">
      {isShowExperiencePageLinks ? (
        <Button
          testId="toggle-experience-tracking-links-button"
          style={{ color: '#347baf', background: 'transparent' }}
          onClick={() => hideExperiencePageLinks()}
          label="Hide experience page tracking links"
        />
      ) : (
        <Button
          testId="toggle-experience-tracking-links-button"
          style={{ color: '#347baf', background: 'transparent' }}
          onClick={() => showExperiencePageLinks()}
          label="Show experience page tracking links"
        />
      )}
    </div>
  )
  const venueUserLinks = _.map(venueUserSlugs, trackingUserLinkRow)
  const commonSlugLinks = _.map(commonSlugs, trackingUserLinkRow)
  const currentVenueUserLink = currentVenueUserSlug ? trackingUserLinkRow(currentVenueUserSlug) : null
  const venuePromoterLinks = _.map(venuePromoterSlugs, trackingPromoterLinkRow)

  return (
    <div>
      {currentVenueUserSlug ? (
        <form style={styles.Form} className="styled" data-sr-validate>
          <div cellPadding="10" style={styles.tableHeaderStyle}>
            <div style={styles.nonLinkColumn}>LINK ID</div>
            <div style={styles.linkColumn}>
              <div style={styles.flex}>
                <span>LINK</span>
                <div>{toggleExperiencesPageTrackingLinkButton()}</div>
              </div>
            </div>
          </div>
          {currentVenueUserLink}
        </form>
      ) : null}
      {_.size(venuePromoterSlugs) ? (
        <form style={styles.Form} className="styled" data-sr-validate>
          <div cellPadding="10" style={styles.tableHeaderStyle}>
            <div style={styles.nonLinkColumn}>BOOKED BY</div>
            <div style={styles.nonLinkColumn}>LINK ID</div>
            <div style={styles.linkColumn}>
              <div style={styles.flex}>
                <span>LINK</span>
                <div>{toggleExperiencesPageTrackingLinkButton()}</div>
              </div>
            </div>
          </div>
          {venuePromoterLinks}
        </form>
      ) : null}
      {_.size(venueUserSlugs) ? (
        <form style={styles.Form} className="styled" data-sr-validate>
          <div cellPadding="10" style={styles.tableHeaderStyle}>
            <div style={styles.nonLinkColumn}>USER</div>
            <div style={styles.nonLinkColumn}>LINK ID</div>
            <div style={styles.linkColumn}>
              <div style={styles.flex}>
                <span>LINK</span>
                <div>{toggleExperiencesPageTrackingLinkButton()}</div>
              </div>
            </div>
          </div>
          {venueUserLinks}
        </form>
      ) : null}
      {_.size(commonSlugs) ? (
        <form style={styles.Form} className="styled" data-sr-validate>
          <div cellPadding="10" style={styles.tableHeaderStyle}>
            <div style={styles.nonLinkColumn}>Social Media</div>
            <div style={styles.nonLinkColumn}>LINK ID</div>
            <div style={styles.linkColumn}>
              <div style={styles.flex}>
                <span>LINK</span>
                <div>{toggleExperiencesPageTrackingLinkButton()}</div>
              </div>
            </div>
          </div>
          {commonSlugLinks}
        </form>
      ) : null}
    </div>
  )
}
