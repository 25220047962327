import { createApi } from '@reduxjs/toolkit/query/react'
import { RTKUtils } from '@sevenrooms/core/api'
import { TrackingLinkImportService } from './TrackingLinkImport'
import type { StartImportAPI, SubmitFileToValidateAPI, ValidationImportData } from './TrackingLinkImportModel'

export const useTrackingLinkImport = createApi({
  reducerPath: 'trackingLinksImportApi',
  baseQuery: RTKUtils.noBaseQuery,
  endpoints: builder => ({
    startTrackingLinksImport: builder.mutation<void, StartImportAPI>({
      queryFn: RTKUtils.rtkQuery(TrackingLinkImportService.startImport),
    }),
    startTrackingLinksImportValidate: builder.mutation<ValidationImportData, SubmitFileToValidateAPI>({
      queryFn: RTKUtils.rtkQuery(TrackingLinkImportService.submitFileToValidate),
    }),
  }),
})

export const { useStartTrackingLinksImportMutation, useStartTrackingLinksImportValidateMutation } = useTrackingLinkImport
