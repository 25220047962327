import StyleUtils from 'mgr/layout/StyleUtils'
import { merge } from 'svr/common/SRUtils'

export const tableStyle = {
  margin: '5px 1%',
  width: '98%',
  clear: 'both',
  minHeight: 35,
  borderBottom: '1px solid #CCC',
}

export const Form = {
  paddingTop: 10,
}

export const NewTrackerLink = {
  backgroundColor: '#CCC',
  width: '95%',
  display: 'inline-block',
  padding: '2%',
}

export const tableHeaderStyle = {
  margin: '1%',
  width: '98%',
  clear: 'both',
  height: '100%',
  minHeight: 30,
  borderBottom: '1px solid #CCC',
  fontWeight: 'bold',
}

export const nonLinkColumn = {
  display: 'inline-block',
  verticalAlign: 'top',
  border: 0,
  width: 130,
  padding: 10,
}

export const linkColumn = {
  display: 'inline-block',
  border: 0,
  width: 600,
}
export const linkTable = {
  padding: 0,
  margin: 0,
}

export const linkTableTD = {
  padding: 0,
  margin: 0,
  border: 0,
}

export const linkTableTDLink = {
  minWidth: 420,
  padding: 0,
  border: 0,
}

export const linkTableTR = {
  minWidth: 130,
}

export const buttonColumn = {
  height: 40,
}

export const addLink = {
  width: 120,
  margin: '5px 10px',
}

export const userLinkStyle = {
  width: 130,
  height: 40,
  padding: '0 10px',
  WebkitAppearance: 'none',
  MozAppearance: 'none',
}

export const neverMindStyle = {
  padding: '0 10px',
  lineHeight: '40px',
}

const action = {
  verticalAlign: 'top',
  backgroundColor: '#f1f1f1',
  backgroundSize: '15px 15px',
  backgroundPosition: '50% 50%',
  backgroundRepeat: 'no-repeat',
  border: '1px solid #ccc',
  borderRadius: '3px',
  cursor: 'pointer',
  display: 'inline-block',
  height: 27,
  marginRight: 15,
  marginBottom: 2,
  width: 24,
}

export const actionLink = mediaUrl => ({
  ...action,
  marginRight: 0,
  backgroundImage: `url(${mediaUrl}images/eventmanager/clone.png)`,
})

export const actionDelete = mediaUrl => ({
  ...action,
  backgroundImage: `url(${mediaUrl}images/eventmanager/delete.png)`,
})

export const flex = {
  display: 'flex',
  justifyContent: 'space-evenly',
  alignItems: 'center',
}

export const dropDownStyle = {
  borderBottom: '1px solid #2C76AA',
  display: 'inline-block',
  lineHeight: '41px',
  height: 41,
  textAlign: 'center',
  width: 41,
  marginLeft: 12,
  transform: 'rotate(90deg)',
  fontSize: '16px',
}
export const dropdownContainer = {
  position: 'relative',
}
export const dropdownMenu = merge(
  {
    backgroundColor: 'white',
    position: 'absolute',
    width: 118,
    top: 48,
  },
  StyleUtils.shadow('rgba(0,0,0,0.68)')
)
export const dropdownItem = {
  width: 'auto',
  lineHeight: '40px',
  height: 40,
  textAlign: 'center',
  paddingRight: 11,
  cursor: 'pointer',
  ':hover': {
    backgroundColor: '#eee',
  },
}
export const hoverStyle = {
  textDecoration: 'none',
  backgroundColor: '#347baf',
}

export const dropdownBody = merge(
  {
    backgroundColor: '#3391D7',
    height: 41,
    lineHeight: '41px',
    textAlign: 'center',
    display: 'inline-block',
    color: 'white',
    fontSize: '14px',
    border: '1px solid #2C76AA',
    padding: '0 0 0 30px',
  },
  StyleUtils.rounded('4px')
)
export const actionButtons = {
  display: 'flex',
  flexDirection: 'row-reverse',
  alignItems: 'center',
}
