import { render } from 'react-dom'
import { Provider } from 'react-redux'
import { applyMiddleware, createStore } from 'redux'
import thunk from 'redux-thunk'
import App from './containers/App'
import linksReducer from './reducers/linksReducer'

const store = createStore(linksReducer, applyMiddleware(thunk))
const venueId = document.getElementById('_venue_id').value
const MEDIA_URL = document.getElementById('_media_url').value
const event_widget_enabled = Boolean(document.getElementById('_event_widget_enabled'))
const dining_widget_enabled = Boolean(document.getElementById('_dining_widget_enabled'))
const new_reservation_widget_enabled = Boolean(document.getElementById('_new_reservation_widget_enabled'))
const force_redirects_on_legacy_widget = Boolean(document.getElementById('_force_redirects_on_legacy_widget'))

render(
  <Provider store={store}>
    <App
      venueId={venueId}
      MEDIA_URL={MEDIA_URL}
      event_widget_enabled={event_widget_enabled}
      dining_widget_enabled={dining_widget_enabled}
      new_reservation_widget_enabled={new_reservation_widget_enabled}
      force_redirects_on_legacy_widget={force_redirects_on_legacy_widget}
    />
  </Provider>,
  document.getElementById('tracking-links')
)
