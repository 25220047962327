import { defineMessages } from '@sevenrooms/core/locales'

export const appMessages = defineMessages({
  importTrackingLinks: {
    id: 'modalTitle',
    defaultMessage: 'Import Tracking Links',
  },
  modalDescription: {
    id: 'modalDescription',
    defaultMessage: 'Use one of the following templates to ensure your data is formatted correctly. 300 rows max.',
  },
  modalTemplates: {
    id: 'modalTemplates',
    defaultMessage: 'Templates',
  },
  advancedTemplate: {
    id: 'advancedTemplate',
    defaultMessage: 'Advanced Template',
  },
  basicTemplate: {
    id: 'basicTemplate',
    defaultMessage: 'Basic Template',
  },
  opentableGuestCenterLabel: {
    id: 'opentableGuestCenterLabel',
    defaultMessage: 'Opentable Guest Center',
  },
  sevenroomsTemplateLabel: {
    id: 'sevenroomsTemplateLabel',
    defaultMessage: '7R Template',
  },
  uploadFileDescription: {
    id: 'sevenroomsTemplateLabel',
    defaultMessage: 'Drag your file here to upload or select a file from your device',
  },
  supportedFilesLabel: {
    id: 'supportedFilesLabel',
    defaultMessage: 'Supported file types: .csv, .xlsx',
  },
  processingData: {
    id: 'processingData',
    defaultMessage: 'Processing data...',
  },
  genericServerError: {
    id: 'genericServerError',
    defaultMessage: 'Server has responded with an error',
  },
  fileProcessingError: {
    id: 'fileProcessingError',
    defaultMessage: 'Upload Error: Please ensure your file is formatted correctly.',
  },
  fileCaption: {
    id: 'fileCaption',
    defaultMessage: 'Drag your file here to upload or <span>select a file</span> from your device',
  },
  import: {
    id: 'import',
    defaultMessage: 'Import',
  },
  newImport: {
    id: 'newImport',
    defaultMessage: 'New import',
  },
  importOverview: {
    id: 'importOverview',
    defaultMessage: 'Import Overview',
  },
  summaryOverview: {
    id: 'summaryOverview',
    defaultMessage: 'A summary of what will be imported',
  },
  inProgress: {
    id: 'inProgress',
    defaultMessage: 'Import in progress',
  },
  inProgressDescription: {
    id: 'inProgressDescription',
    defaultMessage: 'Your import is done, reload page to see result.\n',
  },
  fileName: {
    id: 'fileName',
    defaultMessage: 'File name',
  },
  fileInformation: {
    id: 'fileInformation',
    defaultMessage: 'File information',
  },
  totalRows: {
    id: 'totalRows',
    defaultMessage: 'Total rows',
  },
  totalRowsWithErrors: {
    id: 'totalRowsWithErrors',
    defaultMessage: 'Rows with errors',
  },
  passedValidation: {
    id: 'passedValidation',
    defaultMessage: 'Passed validation',
  },
  downloadErrors: {
    id: 'downloadErrors',
    defaultMessage: 'Download errors',
  },
} as const)
