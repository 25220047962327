import { combineReducers } from 'redux'
import {
  GET_SUCCESS_VENUE_SLUGS,
  UPDATE_FIELD,
  FAILED_VENUE_SLUG_SAVE,
  INITIATE_UPDATE_IN_PROGRESS,
  NEW_VENUE_SLUG,
  NEW_VENUE_SLUG_NEVERMIND,
  SHOW_EXPERIENCE_PAGE_LINKS,
  HIDE_EXPERIENCE_PAGE_LINKS,
} from '../actions/ActionTypes'

const initialSlugState = {}

const venueSlugs = (state = initialSlugState, action) => {
  switch (action.type) {
    case GET_SUCCESS_VENUE_SLUGS:
      return { ...state, ...action.venueSlugsResponse }
    default:
      return state
  }
}

const initialAppState = {
  newTracker: false,
  linkId: '',
  userId: '',
  name: '',
  showExperiencePageLinks: false,
  isUpdating: false,
}

const appState = (state = initialAppState, action) => {
  switch (action.type) {
    case GET_SUCCESS_VENUE_SLUGS:
      return initialAppState
    case UPDATE_FIELD:
      state[action.field] = action.value
      return { ...state, errors: { ...state.errors, [action.fieldName]: action.error } }
    case NEW_VENUE_SLUG:
      return { ...state, newTracker: true }
    case FAILED_VENUE_SLUG_SAVE:
      return { ...state, isUpdating: false }
    case NEW_VENUE_SLUG_NEVERMIND:
      return { ...state, newTracker: false }
    case INITIATE_UPDATE_IN_PROGRESS:
      return { ...state, isUpdating: true }
    case SHOW_EXPERIENCE_PAGE_LINKS:
      return { ...state, showExperiencePageLinks: true }
    case HIDE_EXPERIENCE_PAGE_LINKS:
      return { ...state, showExperiencePageLinks: false }
    default:
      return state
  }
}

const rootReducer = combineReducers({
  venueSlugs,
  appState,
})

export default rootReducer
