import camelcaseKeys from 'camelcase-keys'
import { srPostJson, srPut, throwFetchError, FileService } from '@sevenrooms/core/api'
import type { SubmitFileToValidateAPI, ValidationImportResponse, StartImportAPI, ValidationImportData } from './TrackingLinkImportModel'

export namespace TrackingLinkImportService {
  export async function uploadTrakingLinkFile({ file }: { file: File }) {
    return FileService.uploadFile({
      file,
      rurl: 'tracking-link-import-upload',
      followRedirect: false,
      fileParam: 'import_file',
    })
  }

  export async function submitFileToValidate({ venueId, blobstoreUploadKey }: SubmitFileToValidateAPI): Promise<ValidationImportData> {
    const { data } = await srPostJson<ValidationImportResponse>(
      `/api-yoa/tracking-link-import/${venueId}/`,
      {
        blobstore_upload_key: blobstoreUploadKey,
      },
      {
        skipLoader: true,
        skipLoadClickBlocker: true,
        skipInterfaceError: true,
        json: true,
      }
    ).then(throwFetchError)
    return camelcaseKeys(data, { deep: true })
  }

  export async function startImport({ venueId, blobKeyCsv }: StartImportAPI) {
    await srPut(
      `/api-yoa/tracking-link-import/${venueId}/`,
      {
        blob_key_csv: blobKeyCsv,
      },
      {
        skipLoader: true,
        skipLoadClickBlocker: true,
        skipInterfaceError: true,
        json: true,
      }
    ).then(throwFetchError)
  }
}
