import { configureStore } from '@reduxjs/toolkit'
import { type TypedUseSelectorHook, useSelector } from 'react-redux'
import { rootReducer } from './reducers'

export const store = configureStore({
  reducer: rootReducer,
  devTools: process.env.NODE_ENV === 'development',
})

export type RootState = ReturnType<typeof rootReducer>

export const useStoreSelector: TypedUseSelectorHook<RootState> = useSelector
