import _ from 'lodash'
import React, { Component } from 'react'
import Button from '../../../../common/Button'
import { Select, Input } from '../../../../common/FormElement'
import * as styles from '../assets/styles'

export const NewTrackingLink = ({ MEDIA_URL, bookedbyOptions, updateNewLink, onSaveHandler, onNeverMindHandler, data }) => {
  const slugify = t =>
    t
      .toString()
      .toLowerCase()
      .replace(/[^\u0100-\uFFFF\w\-]/g, '-')
      .replace(/\-\-+/g, '-')
      .replace(/^-+/, '')
      .replace(/\s+/g, '-')
      .replace(/-+$/, '')
  const bookedby_options = _.extend(
    {},
    { '': '' },
    _.zipObject(
      bookedbyOptions.map(o => o.id),
      bookedbyOptions.map(o => o.name)
    )
  )
  const selected_option = _.find(bookedbyOptions, { id: data.userId })
  return (
    <div style={styles.NewTrackerLink}>
      <Select
        name="userId"
        value={data.userId}
        onChangeHandler={updateNewLink}
        style={{ clear: 'none', marginRight: 10 }}
        preInputStyle={{ width: 110 }}
        inputCss={styles.userLinkStyle}
        MEDIA_URL={MEDIA_URL}
        options={bookedby_options}
      />
      <Input
        type="text"
        name="linkId"
        value={slugify((selected_option || {}).name || '')}
        onChangeHandler={updateNewLink}
        style={{ float: 'left', padding: '0 10px' }}
        inputCss={{ height: 30 }}
      />
      <Button
        buttonStyle={{ float: 'left', padding: '0 10px' }}
        label="Save"
        disabled={data.isUpdating}
        onClick={onSaveHandler}
        style={{ width: 30 }}
      />
      <a onClick={onNeverMindHandler} style={styles.neverMindStyle}>
        Nevermind
      </a>
    </div>
  )
}
